








































































































































































































































































































































































































.header-dados-estatisticos::v-deep {
  .v-input__slot {
    margin-bottom: 0 !important;
  }

  .v-text-field.v-text-field--solo .v-input__control {
    min-height: 35px;
  }
}

.card-estatistica::v-deep {
  .v-card__title {
    font-size: 1rem;
    padding: 10px;
    line-height: 1.2rem;
  }

  .v-list-item {
    padding: 0 10px;
  }

  .v-list-item__content {
    padding: 3px 0;
    font-size: 0.8rem;

    strong {
      font-size: 0.7rem;
    }

    &.align-end {
      width: 100% !important;
      display: block;
      text-align: right;
    }
  }

  .v-list-item--dense, .v-list--dense .v-list-item {
    min-height: 25px;
  }
}

